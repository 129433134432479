.flyerContainer{
  align-items: center;
  width: 100%;
}

.flyerImage {
    padding-top: 200px;
    display: block;
    width: 80%;
    max-width: 1100px;
    max-height: 440px;
    height: auto;
    margin: 0 auto;
    border: none;
  }


  .QHacemosContainer {
    padding-top: 10%;
    padding-bottom: 5%;
    width: 70%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;
  }

  .QHacemosTexto {
    width: 50%;
    font-weight: 700;
    font-size: 30px;
    color: white;
    text-align: left;
  }
  
  .QHacemosTexto h2 {
    margin-top: 0;
    width: 100%;
    white-space: nowrap;
  }
  .QHacemosTexto p {
    width: 100%;
  }

  .slider2{
    width: 40%;
    align-items: center;
    justify-content: center;
  }

  .ParqueExterior {
    width: 100%;
    height: 400px;
    border-radius: 30px;
  }

  .RyOContainer {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 10%;
    padding-bottom: 10%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  
  .redesContainer,
  .ofertasContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: flex-end;
  }

  .fotoRedes{
    max-width: 400px;
    height: 600px;
    margin-bottom: 50px;

  }
  .fotoOfertas{
    max-width: 400px;
    height: 400px;
    margin-bottom: 150px;
  }

  .botonRedes{
    max-width: 450px;
  }
  .botonOfertas{
    max-width: 450px;
  }

  .marcasContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 300px;
    height: auto;
    border: 10px solid #FFFC05;
    box-sizing: border-box;
    margin-bottom: 5%;
    background-color:#FFFC05 ;
    padding-top: 3px;
  }
  
  .swiper {
    width: 100%;
    padding: 0;
  }
  
  .marcasImage {
    width: 100%;
    max-height: 300px;
    height: auto;
  }

  .videoContainer {
    width: 60%; 
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 5%;
  }
  
  .video {
    width: 100%;
    height: auto;
    display: block;
  }

  @media (max-width: 1500px) {
    .QHacemosContainer {
      width: 70%;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
    }
    .QHacemosTexto {
      font-weight: 600;
      font-size: 25px;
    }
    
    .QHacemosTexto h2 {
      width: 100%;
      white-space: nowrap;
    }
    .QHacemosTexto p {
      width: 100%;
    }
  
    .ParqueExterior {
      height: 350px;
    }

    .fotoRedes{
      max-width: 300px;
      height: 500px;
      margin-bottom: 50px;
  
    }
    .fotoOfertas{
      max-width: 300px;
      height: 300px;
      margin-bottom: 150px;
    }
  
    .botonRedes{
      max-width: 350px;
    }
    .botonOfertas{
      max-width: 350px;
    }
  }

  @media (max-width: 1200px) {
    .QHacemosContainer {
      width: 70%;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
    }
    .QHacemosTexto {
      font-weight: 600;
      font-size: 22px;
    }
    
    .QHacemosTexto h2 {
      width: 100%;
      white-space: nowrap;
    }
    .QHacemosTexto p {
      width: 100%;
    }
  
    .ParqueExterior {
      height: 300px;
    }

    .fotoRedes{
      max-width: 300px;
      height: 500px;
      margin-bottom: 50px;
  
    }
    .fotoOfertas{
      max-width: 300px;
      height: 300px;
      margin-bottom: 150px;
    }
  
    .botonRedes{
      max-width: 300px;
    }
    .botonOfertas{
      max-width: 300px;
    }
  }

  @media (max-width: 1000px) {
    .QHacemosTexto h2 {
      font-size: 28px;
    }
  }

  @media (max-width: 900px) {
    .QHacemosContainer {
      width: 70%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
    }
    .QHacemosTexto {
      font-weight: 600;
      font-size: 22px;
      width: 100%;
    }
    
    .QHacemosTexto h2 {
      width: 100%;
      white-space: nowrap;
    }
    .QHacemosTexto p {
      width: 100%;
    }
  
    .slider2 {
      margin-top: 25px;
      width: 90%;
      align-items: center;
      justify-content: center;
    }
    
    .RyOContainer {
      padding-left: 15%;
      padding-right: 15%;
      padding-top: 10%;
      padding-bottom: 10%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
    }
    .fotoRedes{
      max-width: 300px;
      height: 500px;
      margin-bottom: 50px;
  
    }
    .fotoOfertas{
      max-width: 300px;
      height: 300px;
      margin-top: 100px;
      margin-bottom: 100px;
    }
  
    .botonRedes{
      max-width: 300px;
    }
    .botonOfertas{
      max-width: 300px;
    }
  }

  @media (max-width: 800px) {
    .flyerImage {
      padding-top: 180px;
    }
    .videoContainer {
      width: 70%; 
      padding: 15px;
    }
  }

  @media (max-width: 600px) {
    .flyerImage {
      padding-top: 170px;
    }
    .slider2 {
      margin-top: 25px;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .ParqueExterior {
      height: 230px;
    }

    .QHacemosTexto h2 {
      font-size: 25px;
    }
    .QHacemosTexto p {
      font-size: 20px;
    }

    .marcasContainer {
      border: 8px solid #FFFC05;
      margin-bottom: 45px;
    }
  }

  @media (max-width: 450px) {
    .flyerImage {
      padding-top: 150px;
    }
    .slider2 {
      margin-top: 25px;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .ParqueExterior {
      height: 220px;
    }

    .QHacemosTexto h2 {
      font-size: 20px;
    }
    .QHacemosTexto p {
      font-size: 17px;
    }

    .RyOContainer {
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 10%;
      padding-bottom: 10%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
    }
    .fotoRedes{
      max-width: 250px;
      height: 400px;
      margin-bottom: 30px;
  
    }
    
    .fotoOfertas{
      max-width: 200px;
      height: 200px;
      margin-top: 60px;
      margin-bottom: 50px;
    }
  
    .botonRedes{
      max-width: 200px;
    }
    .botonOfertas{
      max-width: 200px;
    }

    .marcasContainer {
      border: 7px solid #FFFC05;
      margin-bottom: 40px;
    }

    .videoContainer {
      width: 70%; 
      padding: 10px;
    }
  }

  @media (max-width: 350px) {
    .QHacemosTexto h2 {
      font-size: 18px;
    }
    .QHacemosTexto p {
      font-size: 15px;
    }

    .videoContainer {
      width: 70%; 
      padding: 5px;
    }
  }