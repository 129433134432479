.navbar {
    background-color: #FFFC05;
    color: #000000; 
    display: flex;
    align-items: center;
    width: 100vw;
    transition: background-color 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 120px;
  }
  
  .fixedNavbar {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .logo {
    padding-left: 5%;
  }
  
  .sections{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .sections ul {
    list-style: none;
    display: flex;
  }
  
  .sections ul li {
    margin-right: 160px;
    cursor: pointer;
    font-size: 22px;
    font-weight: 550;
  }

  @media (max-width: 1700px) {
    .logo {
      padding-left: 5%;
      margin-left: 3%;
    }
  
    .sections ul li {
      margin-right: 120px;
    }
  }

  @media (max-width: 1250px) {
    .logo {
      padding-left: 5%;
      margin-left: 3%;
    }
  
    .sections ul li {
      margin-right: 70px;
    }
  }

@media (max-width: 1000px) {
    .logo {
      padding-left: 3%;
      margin-left: 1%;
    }
  }

@media (max-width: 800px) {
    .logo {
      padding-left: 3%;
      margin-left: 1%;
      width: 240px;
      height: 60px;
    }
}

@media (max-width: 500px) {
  .logo {
    width: 200px;
    height: 50px;
  }
}

@media (max-width: 400px) {
  .logo {
    width: 160px;
    height: 40px;
  }
}