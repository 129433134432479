.container{
    padding-top: 5%;
    width: 70%;
    margin: 0 auto;
    padding-bottom: 5%;
}
.mapa{
    width: 100%;
    height: 500px;
    border-radius: 20px;
}

.title{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 30px;
    text-align: left;
    margin-bottom: 3%;
}

.text{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    margin-bottom: 3%;
}

.text span{
    font-weight: 700;
    font-size: 23px;
}


@media (max-width: 1100px) {

    .title{
        font-size: 25px;
    }
  }

  @media (max-width: 940px) {
    .title{
        font-size: 22px;
    }
  }

  @media (max-width: 940px) {
    .title{
        font-size: 22px;
    }
    
  }

  @media (max-width: 600px) {
    
    .title{
     font-size: 20px;
    }
   }

   @media (max-width: 400px) {
    .title{
     font-size: 18px;
    }
   }
  