.Container{
    padding-top: 5%;
    background-color:#FFFC05 ;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 3%;
}

.titulo{
    color: #DC0B15;
    font-weight: 700;
    font-size: 30px;
    text-align: left;
}

.texto{
    color: #204796;
    font-weight: 700;
    font-size: 25px;
    text-align: left;
}

.img{
    border-radius: 30px;
    width: 85%;
    height: 250px;
    margin-left: 50px;
}

.nuestroPersonal{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.swiperContainer{
    width: 500px;
}
@media (max-width: 1100px) {
   
    .titulo{
        font-size: 25px;
    }
    .texto{
        font-size: 20px;
        text-align: left;
    }
 
    .swiperContainer{
        width: 450px;
    }
  
  }

  @media (max-width: 1000px) {
    .swiperContainer{
        width: 400px;
    }
    .img{
        height: 280px;
    }
    
  }

  @media (max-width: 940px) {
    .titulo{
        font-size: 22px;
    }
    .texto{
        font-size: 17px;
        text-align: left;
    }
    .swiperContainer{
        width: 350px;
        height: 200px;
    }
    .img{
        height: 200px;
    }
    
  }

  @media (max-width: 940px) {
    .titulo{
        font-size: 22px;
    }
    .texto{
        font-size: 17px;
        text-align: left;
    }
  }

  @media (max-width: 800px) {
   .nuestroPersonal{
    display: flex;
    flex-direction: column;
   }
   .titulo{
    text-align: center;
   }
 
   .swiperContainer{
    width: 80%;
    height: 220px;
    margin-left: 10%;
    margin-right: 10%;
    }
    .img{
        margin: 0;
        width: 100%;
    }
  }

  @media (max-width: 600px) {
    
    .titulo{
     order: 1;
     text-align: center;
     font-size: 20px;
    }
    .texto{
        font-size: 15px;
    }
    .swiperContainer{
        width: 100%;
        height: 220px;
        margin: 0;
        }
        .img{
            margin: 0;
            width: 100%;
        }
   }

   @media (max-width: 400px) {
    .nuestroPersonal{
     display: flex;
     flex-direction: column;
    }
    .titulo{
     order: 1;
     text-align: center;
     font-size: 18px;
    }
    .texto{
        font-size: 12px;
    }
    .swiperContainer{
        width: 100%;
        height: 150px;
        margin: 0;
        }
        .img{
            height: 150px;
        }
   }
  